@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CustomFont';
  src: url('./pocket-calculator/DigitalNumbers-Regular.ttf') format('truetype'),
}

*, ::after, ::before {
  margin: 0;
  padding: 0;
  background-color: black;
}

body {
  font-family: 'CustomFont', sans-serif;
  font-weight: 100;
  font-style: normal;

}

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 400px;
}

.Home-container {

  max-width: 100%;
  /* margin: 0 auto; */
  padding: 20px;
  overflow: hidden;
}

.search-input {
  height: 40px;
  width: 400px;
  font-size: 18px;
  border: 1px solid black;
}

.search-input::placeholder {
  color: #4b4b4b;
}

.empty-state {
  transform: translateX(50%, 10%);
}

/* .card-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #000;
  color: #fff;
  padding: 10px;
  line-height: 20px;
  letter-spacing: 1.5px;
  font-size: 18px;
  text-transform: uppercase;
} */

.card-container {
  flex-wrap: wrap; /* Ensure content wraps to the next line */
  background-color: #000;
  color: #fff;
  width: 100%;
  letter-spacing: 0.5px;
  font-size: 12px;
  text-transform: uppercase;
  overflow: hidden; /* Hide any content that overflows */
}



.highlight {
  background-color: #4989A7;
}

:where(.css-dev-only-do-not-override-42nv3w).ant-empty .ant-empty-description {
  color: #fff;

}
